<template>
  <div>
    <div class="buttons-block is-flex is-justify-content-space-between ">
      <a href="" @click.prevent="$parent.$parent.open(1, dat)">
        <i title="Modifiko" class="fa fa-pencil"></i>
      </a>
      <a href="" @click.prevent="$parent.$parent.open(0, dat)">
        <i title="Shiko" class="fa fa-folder-open"></i>
      </a>
      <a v-if="dat.is_recipe == 1" href="" @click.prevent="$parent.$parent.open(2, dat)">
        <i title="Shto materiale per kete produkt" class="fa fa-list"></i>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'actions',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  watch: {
    data: {
      handler: function (val ) {
        this.dat = val
      },
      deep: true
    }
  },
  setup(props) {
    return {
      dat: props.data
    }
  }
}
</script>
